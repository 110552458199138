<template>
  <div class="content">
    <section class="first">
      <h1>Important Safety Information for Wegovy<sup>®</sup></h1>
      <button @click="toggleISI"><font-awesome-icon :icon="'close'" /></button>
    </section>
    <section>
      <div class="isi-blackbox"><p><strong>WARNING: RISK OF THYROID C-CELL TUMORS</strong></p>
        <ul>
          <li><strong>In rodents, semaglutide causes dose-dependent and treatment-duration-dependent thyroid C-cell tumors at clinically relevant exposures. It is unknown whether Wegovy<sup>®</sup> causes thyroid C-cell tumors, including medullary thyroid carcinoma (MTC), in humans as human relevance of semaglutide-induced rodent thyroid C-cell tumors has not been determined</strong></li>
          <li><strong>Wegovy<sup>®</sup> is contraindicated in patients with a personal or family history of MTC or in patients with Multiple Endocrine Neoplasia syndrome type 2 (MEN 2). Counsel patients regarding the potential risk for MTC with the use of Wegovy<sup>®</sup> and inform them of symptoms of thyroid tumors (e.g. a mass in the neck, dysphagia, dyspnea, persistent hoarseness). Routine monitoring of serum calcitonin or using thyroid ultrasound is of uncertain value for early detection of MTC in patients treated with Wegovy<sup>®</sup></strong></li>
        </ul>
      </div>
    </section>
    <section>
      <h1>Indications and Usage</h1>
      <p>Wegovy<strong><sup>® </sup></strong>(semaglutide) injection 2.4 mg is indicated in combination with a reduced calorie diet and increased physical activity:</p>
      <ul>
        <li>to reduce the risk of major adverse cardiovascular events (cardiovascular death, non-fatal myocardial infarction, or non-fatal stroke) in adults with established cardiovascular disease and either obesity or overweight</li>
        <li>to reduce excess body weight and maintain weight reduction long term in adults and pediatric patients aged 12 years and older with obesity and adults with overweight in the presence of at least one weight-related comorbidity</li>
      </ul>
      <p><strong style="color:#0092BC;">Limitations of Use:</strong> Wegovy<sup>®</sup> contains semaglutide. Coadministration with other semaglutide-containing products or with any GLP-1 receptor agonist is not recommended</p>
    </section>
    <section>
      <h1>Important Safety Information</h1>
      <h2>Contraindications<br>
      </h2>
      <ul>
        <li>Wegovy<sup>®</sup> is contraindicated in patients with a personal or family history of MTC or in patients with MEN 2, and in patients with a prior serious hypersensitivity reaction to semaglutide or to any of the excipients in Wegovy<sup>®</sup>. Serious hypersensitivity reactions, including anaphylaxis and angioedema have been reported with&nbsp;Wegovy<sup>®</sup></li>
      </ul>
    </section>
    <section>
      <h2>Warnings and Precautions</h2>
      <ul>
        <li><strong>Risk of Thyroid C-Cell Tumors:</strong>&nbsp;Patients should be further evaluated if serum calcitonin is measured and found to be elevated or thyroid nodules are noted on physical examination or neck imaging</li>
        <li><strong>Acute Pancreatitis:</strong>&nbsp;Acute pancreatitis, including fatal and non-fatal hemorrhagic or necrotizing pancreatitis, has been observed in patients treated with GLP-1 receptor agonists, including semaglutide. Acute pancreatitis was observed in patients treated with Wegovy<sup>®</sup> in clinical trials. Observe patients carefully for signs and symptoms of acute pancreatitis (including persistent severe abdominal pain, sometimes radiating to the back, and which may or may not be accompanied by vomiting). If acute pancreatitis is suspected, discontinue Wegovy<sup>®</sup> promptly, and if acute pancreatitis is confirmed, do not restart</li>
        <li><strong>Acute Gallbladder Disease:</strong> Treatment with Wegovy<sup>®</sup>&nbsp;is associated with an increased occurrence of cholelithiasis and cholecystitis. The incidence of cholelithiasis and cholecystitis was higher in Wegovy<sup>®</sup> pediatric patients aged 12 years and older than in Wegovy<sup>®</sup> adults. In clinical trials in adult patients, cholelithiasis was reported by 1.6% of Wegovy<sup>®</sup> patients and 0.7% of placebo patients. Cholecystitis was reported by 0.6% of Wegovy<sup>®</sup> patients and 0.2% of placebo patients. In a clinical trial in pediatric patients aged 12 years and older, cholelithiasis was reported by 3.8% of Wegovy<sup>®</sup> patients and 0% placebo patients. Cholecystitis was reported by 0.8% of Wegovy<sup>®</sup> pediatric patients and 0% placebo patients. Substantial or rapid weight loss can increase the risk of cholelithiasis; however, the incidence of acute gallbladder disease was greater in Wegovy<sup>®</sup> patients than in placebo patients, even after accounting for the degree of weight loss. If cholelithiasis is suspected, gallbladder studies and appropriate clinical follow-up are indicated</li>
        <li><strong>Hypoglycemia:</strong>&nbsp;Wegovy<strong><sup>®</sup></strong> lowers blood glucose and can cause hypoglycemia. In a trial of adult patients with type 2 diabetes, hypoglycemia was reported in 6.2% of Wegovy<sup>®</sup> patients versus 2.5% of placebo patients. Patients with diabetes taking Wegovy<sup>®</sup> with an insulin or insulin secretagogue (e.g. sulfonylurea) may have an increased risk of hypoglycemia, including severe hypoglycemia. The use of Wegovy<sup>®</sup> in patients with type 1 diabetes or in combination with insulin has not been evaluated. Inform patients of the risk of hypoglycemia and educate them on the signs and symptoms. Monitor blood glucose in patients with diabetes</li>
        <li><strong>Acute Kidney Injury:</strong>&nbsp;There have been postmarketing reports of acute kidney injury and worsening of chronic renal failure, which in some cases required hemodialysis, in patients treated with semaglutide. Patients with renal impairment may be at a greater risk of acute kidney injury, but some events have been reported in patients without known underlying renal disease. A majority of the events occurred in patients who experienced nausea, vomiting, or diarrhea, leading to volume depletion. Monitor renal function when initiating or escalating doses of Wegovy<sup>®</sup> in patients reporting severe adverse gastrointestinal reactions and in patients with renal impairment reporting any adverse reactions that could lead to volume depletion</li>
        <li><strong>Hypersensitivity Reactions:</strong>&nbsp;Serious hypersensitivity reactions (e.g., anaphylaxis, angioedema) have been reported with Wegovy<sup>®</sup>. If hypersensitivity reactions occur, discontinue use of Wegovy<sup>®</sup>, treat promptly per standard of care, and monitor until signs and symptoms resolve. Use caution in a patient with a history of anaphylaxis or angioedema with another GLP-1 receptor agonist</li>
        <li><strong>Diabetic Retinopathy Complications in Patients with Type 2 Diabetes:</strong>&nbsp;In a trial of adult patients with type 2 diabetes, diabetic retinopathy was reported by 4.0% of Wegovy<sup>®</sup> patients and 2.7% of placebo patients. Rapid improvement in glucose control has been associated with a temporary worsening of diabetic retinopathy. Patients with a history of diabetic retinopathy should be monitored for progression of diabetic retinopathy</li>
        <li><strong>Heart Rate Increase:</strong>&nbsp;Mean increases in resting heart rate of 1 to 4 beats per minute (bpm) were observed in Wegovy<sup>®</sup>&nbsp;adult patients compared to placebo in clinical trials. More Wegovy<sup>®</sup>&nbsp;adult patients compared with placebo had maximum changes from baseline of 10 to 19 bpm (41% versus 34%) and 20 bpm or more (26% versus 16%). In a clinical trial in pediatric patients aged 12 years and older with normal baseline heart rate, more patients treated with Wegovy<sup>®</sup> compared to placebo had maximum changes in heart rate of 20 bpm or more (54% versus 39%). Monitor heart rate at regular intervals and instruct patients to report palpitations or feelings of a racing heartbeat while at rest. If patients experience a sustained increase in resting heart rate, discontinue Wegovy<sup>®</sup></li>
        <li><strong>Suicidal Behavior and Ideation:</strong>&nbsp;Suicidal behavior and ideation have been reported in clinical trials with other weight management products. Monitor patients for depression, suicidal thoughts or behavior, and/or any unusual changes in mood or behavior. Discontinue Wegovy<sup>®</sup> in patients who experience suicidal thoughts or behaviors and avoid in patients with a history of suicidal attempts or active suicidal ideation</li>
      </ul>
    </section>
    <section>
      <h2>Adverse Reactions</h2>
      <ul>
        <li>Most common adverse reactions (incidence ≥5%) are: nausea, diarrhea, vomiting, constipation, abdominal pain, headache, fatigue, dyspepsia, dizziness, abdominal distention, eructation, hypoglycemia in patients with type 2 diabetes, flatulence, gastroenteritis, gastroesophageal reflux disease, and nasopharyngitis</li>
      </ul>
    </section>
    <section>
      <h2>Drug Interactions</h2>
      <ul>
        <li>The addition of Wegovy<sup>®</sup> in patients treated with insulin has not been evaluated. When initiating Wegovy<sup>®</sup>, consider reducing the dose of concomitantly administered insulin secretagogues (such as sulfonylureas) or insulin to reduce the risk of hypoglycemia</li>
        <li>Wegovy<sup>®</sup> causes a delay of gastric emptying and has the potential to impact the absorption of concomitantly administered oral medications. Monitor the effects of oral medications concomitantly administered with Wegovy<sup>®</sup></li>
      </ul>
    </section>
    <section>
      <h2>Use in Specific Populations</h2>
      <ul>
        <li><strong>Pregnancy:</strong>&nbsp;May cause fetal harm. When pregnancy is recognized, discontinue Wegovy<sup>®</sup>. Discontinue Wegovy<sup>®</sup> in patients at least 2 months before a planned pregnancy</li>
        <li><strong>Pediatric:</strong>&nbsp;Adverse reactions with Wegovy<strong><sup>® </sup></strong>in pediatric patients aged 12 years and older were similar to those reported in adults. Pediatric patients ≥12 years of age treated with Wegovy<strong><sup>®</sup></strong> had greater incidences of cholelithiasis, cholecystitis, hypotension, rash, and urticaria compared to adults treated with Wegovy<strong><sup>®</sup></strong>. There are insufficient data in pediatric patients with type 2 diabetes treated with Wegovy<sup>®</sup> for obesity to determine if there is an increased risk of hypoglycemia with Wegovy<sup>®</sup> treatment similar to that reported in adults</li>
        <li><strong>Geriatric:</strong> In the cardiovascular outcomes trial, patients aged 75 years and older reported more hip and pelvis fractures on Wegovy<sup>®</sup> than placebo. Patients aged 75 years and older (Wegovy<sup>® </sup>and placebo) reported more serious adverse reactions overall compared to younger adult patients</li>
      </ul>
    </section>
  </div>
</template>
<script>

export default {
  name: 'PiComponent',
  setup(props, { emit }) {
    const toggleISI = () => {
      emit('closeISI');
    };
    return {toggleISI};
  }
}
</script>
<style scoped lang="scss">
.content{background: #fff;
  h1 {font-size: 16px;line-height: 27px;color: #0092BC;}
  h2 {color: #0092BC;font-size: 14px;line-height: 24px;margin: 30px 0 15px 0;font-weight: bold;}
  ul{margin-left:0;padding-left:0;}
  section{
    &:first-of-type h1:first-of-type{top: 0;margin: 0;background: #fff;z-index: 10;padding-top:12px;padding-bottom:12px;padding-right:45px;}
    &.first{position: sticky;top:0;
      button{color: #001965;position: absolute;right: 0;top: 50%;padding:0;transform:translate(50%,-50%);background: none;border: none;font-size: 24px;cursor: pointer;
        &:hover,&:focus{color: #0092BC;}
      }
    }
  }
  .isi-blackbox{padding: 20px;border: 2px solid #001965;
    p{line-height: 24px;}
  }
}
@media screen and (max-aspect-ratio: 1/1) {
  .content{padding:0 min(4vw,30px) min(4vw,30px) min(4vw,30px);}
}
@media screen and (min-aspect-ratio: 1/1) {
  .content{padding: 0 min(4vh,30px) min(4vh,30px) min(4vh,40px);}
}
</style>